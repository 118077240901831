import { React, Fragment } from 'react';
import { Typography } from '@mui/material';
import './errorpage.css';


export default function ErrorPage(props){

    return(
        <Fragment>
            <div className='error-container'>
                <div className='error-left'>
                    <img className='error-img' src={process.env.PUBLIC_URL + '/images/sad-face-icon.png'} alt="Error" />
                </div>
                <div className='error-right'>
                    <div className='error-right-container'>
                        <Typography variant='subtitle1' sx={{color : 'grey', padding : 5}}>
                            Woops! Something went wrong!
                        </Typography>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}