import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import Card from '@mui/material/Card';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import './workoutheader.css';

export default function WorkoutHeader(props) {
  return (
    <div className='workoutheader-container'>
        <div className='workoutheader-left'>
            <Card sx={{ minWidth: 300, maxWidth : 500, color: "white", bgcolor : 'transparent', boxShadow : 'unset' }}>
                <CardHeader sx={{ marginTop : 1 }}
                    avatar={
                    <Avatar aria-label="workout" sx={{ bgcolor : "rgba(249,88,54,1)" }}>
                        <FitnessCenterIcon />
                    </Avatar>
                    }
                    title={props.title}
                    titleTypographyProps={{ fontWeight : "bolder", fontSize: 19, textAlign : 'center' }}
                />
            </Card>
        </div>
        <div>
            <div className='workoutheader-right-name'>
                <Typography variant="subtitle1" color="rgba(249,88,54,1)" align='center' >
                    <b>{props.athlete_name}</b>
                </Typography>
            </div>
            <div className='workoutheader-right'>
                <List
                    sx={{
                        width: '100%',
                        maxWidth: 380,
                        bgcolor: 'transparent',
                    }}
                    >
                    <ListItem>
                        <ListItemAvatar>
                        <Avatar sx={{ bgcolor : 'rgb(77, 185, 62)' }}>
                            <EventAvailableIcon />
                        </Avatar>
                        </ListItemAvatar>
                        <ListItemText sx={{ width : 100, color: "white" }} primary={props.workout_start} />
                    </ListItem>
                    </List>
                        
                        <span className='workoutheader-divider'></span>
                        
                    <List
                    sx={{
                        width: '100%',
                        maxWidth: 380,
                        bgcolor: 'transparent',
                    }}
                    >
                    <ListItem>
                        <ListItemAvatar >
                        <Avatar sx={{ bgcolor : '#780001' }}>
                            <EventBusyIcon />
                        </Avatar>
                        </ListItemAvatar>
                        <ListItemText sx={{ width : 100, color: "white"  }} primary={props.workout_end} />
                    </ListItem>
                </List>
            </div>
        </div>
    </div>
  );
}