import { useEffect } from "react";
import { Auth } from "aws-amplify";
import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';
import { useNavigate, useLocation } from 'react-router';
import '@aws-amplify/ui-react/styles.css';
import './login.css';

export function Login() {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || '/';
  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true });
      
      Auth.currentSession().then(res=>{
        let accessToken = res.getAccessToken()
        let jwt = accessToken.getJwtToken()
      })
    }
  }, [route, navigate, from]);
  return (
    <div className="login-container">
      <div className="login-header">
        <img src="images/le_logo_orange.png" className="login-logo" alt="Login"/>
      </div>
      <View className="auth-wrapper">
          <Authenticator
            hideSignUp={true}
            loginMechanisms={['email']}>
          </Authenticator>
      </View>
    </div>
  );
}