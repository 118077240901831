import * as React from 'react';
import { Fragment } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import './media.css'
import Header from '../../components/header/Header';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from 'react';
import { getMediaList } from '../../apis/apiservice';
import PageLoader from '../../components/pageloader/PageLoader';
import ActionEdit from '../../components/actionedit/ActionEdit';
import ErrorPage from '../../components/errorpage/ErrorPage';
import FloatngAction from '../../components/floatingaction/FloatingAction';

const columns  = [
  { 
    field: 'name', 
    headerName: 'Nome', 
    width: 500,
    editable: false,
  },
  {
    field: 'category',
    headerName: 'Categoria',
    width: 250,
    editable: false,
  },
  {
    field: 'url',
    headerName: 'URL',
    width: 350,
    editable: false,
    type: "link",
    renderCell: (params) => (
        <a
          href={params.value}
          target='_blank'
          rel="noreferrer"
        >
          {params.value}
        </a>
    )
  },
  {
    field: 'videoId',
    headerName: 'Ações',
    width: 140,
    editable: false,
    renderCell: (params) => (
      <ActionEdit id={params.id} name={params.row.name} url={params.row.url} category={params.row.category}/>
    )
  }
];

export default function Media(){

  const [data, setData] = useState();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
     try {
       const response = await getMediaList()

       setData(response.data);
       setLoading(false);
       setError(false);
     } 
     catch (error) 
     {
       console.error('Erro ao obter dados:', error);
       setLoading(false);
       setError(true);
     }
    }
    fetchData();
  }, []);

return (
  <Fragment>
    <Header/>
    <div className='media-container'>
      <Fragment>
        {isLoading ? <PageLoader/> :
          <Fragment>
            {error? <ErrorPage/> :
              <Fragment>
              <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <Box sx={{ height: "70vh", width: '100%' }}>
                  <DataGrid
                    rows={data}
                    columns={columns}
                    loading={isLoading}
                    getRowId={(row) => row.videoId}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 25,
                        },
                      },
                    }}
                    pageSizeOptions={[10, 50, 100]}
                  />
                </Box>
              </Paper>
              <FloatngAction/>
              </Fragment>
          }
          </Fragment>
        }
      </Fragment>
    </div>
  </Fragment>
);

}