import { Typography } from '@mui/material';
import * as React from 'react';
import Header from '../../components/header/Header';
import "./landing.css";


export const landing_urls = [
  {
    title: 'Treinos',
    subheader: 'Lista de treinos',
    avatar: "/images/avatars/list.png",
    alt: "Treinos",
    url: '/workoutlist',
    active: true
  },
  {
    title: 'Novo',
    subheader: 'Criar novo treino',
    avatar: "/images/avatars/workout.png",
    alt: "Novo",
    url: "/new",
    active: true
  },
  {
    title: 'Templates',
    subheader: 'Visualizar Templates',
    avatar: "/images/avatars/templates.png",
    alt: "Templates",
    url: "/templates",
    active: false
  },
  {
    title: 'Mídias',
    subheader: 'Gerenciar lista de mídias',
    avatar: "/images/avatars/media.png",
    alt: "Mídias",
    url: "/media",
    active: true
  },
];

const handleClick = (idx) => {
  const locate = landing_urls[idx]["url"]
  window.location.assign(locate)
}

function LandingContent() {
  return (
      <React.Fragment>
        <Header/>
          <div className='landing-container'>
            {landing_urls.map((tier, idx) => (
              tier.active ?
              <div className='landing-card' onClick={() => handleClick(idx)}>
                <div className='landing-card-top'>
                  <img className='landing-card-img' src={tier.avatar} alt={tier.title}/>
                </div>
                <div className='landing-card-bottom'>
                  <Typography variant='h5' align='right' sx={{padding : 2}}>
                    {tier.subheader}
                  </Typography>
                </div>
              </div>
              :
              null
            ))}
          </div>
      </React.Fragment>
      )
  }

export default function Landing() {
  return <LandingContent />;
}