import React from 'react';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import AppBar from '@mui/material/AppBar';
import './Header.css'
import { Authenticator } from '@aws-amplify/ui-react';

export default function Header(){

    return(
        <div className='header-container'>
            <div className='global-header'>
                <AppBar
                    position="static"
                    elevation={0}
                    sx={{ backgroundColor : "#1e1e1e" }}
                >
                    <Toolbar sx={{ flexWrap: 'wrap' }} variant='string'>
                        <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                            <Link href="/app" underline="none" color="#ffffff">
                                <img src="/images/HorizontalPrancheta 1.png" alt='Leonardo Eliseo Personal Trainer' className='header-logo'/>
                            </Link>
                        </Typography>
                        <Authenticator 
                            hideSignUp={true}
                            loginMechanisms={['email']}>
                            {({ signOut, user }) => (
                                <div className='header-logout-container'>
                                    <div className='header-login-name'>
                                        <p>{user.attributes.name}</p>
                                    </div>
                                <button className='header-login-button' onClick={signOut}>Logout</button>
                                </div>
                            )}
                        </Authenticator>
                    </Toolbar>
                </AppBar>
            </div>
        </div>
    )
}