import React from 'react';
import { Fragment, Component } from 'react';
import WorkoutList from '../../components/workoutlist/WorkoutList';
import WorkoutHeader from '../../components/workoutheader/WorkoutHeader';
import WorkoutInfo from '../../components/workoutinfo/WorkoutInfo';
import './desktopassessment.css'

class DesktopAssessment extends Component{
  constructor(props){
      super(props);
      this.state = {
        isLoading: null,
        data: "teste"
      }
  }

  render(){

    return (
        <Fragment>
          <div className='destkop-container'>
            <div className='desktop-header'> 
              <div className='desktop-header-title'>
                <img className='desktop-header-title-image' alt="Leonardo Eloseo Personal Trainer" src={process.env.PUBLIC_URL + '/images/HorizontalPrancheta 1.png'} />
              </div>
            </div>

            <div className='desktop-content-container'>

              <div className='desktop-content-workouts'>
                <WorkoutHeader title={this.props.data.workout_name} workout_start={this.props.data.workout_start} workout_end={this.props.data.workout_end} athlete_name={this.props.data.athlete_name}/>
              </div>

              <div className='desktop-content-instructions'>
                <WorkoutInfo/>
              </div>

              <div className='desktop-content-workouts'>
                <WorkoutList train_data={this.props.data.train_data}/>
              </div>

            </div>
          </div>
        </Fragment>
      )
    }

}

export default DesktopAssessment;