import React from 'react';
import {Fragment, Component } from 'react';
import TrainDrawer from '../../components/traindrawer/TrainDrawer';
import Header from '../../components/header/Header';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Typography from "@mui/material/Typography"
import { getStorageItem, setStorageItem } from "../../components/storagehandler/StorageHandler";
import moment from 'moment/moment';
import {getMediaList, creatWorkout} from '../../apis/apiservice';
import PageLoader from '../../components/pageloader/PageLoader';
import Box from '@mui/material/Box';
import "./New.css";
import ErrorPage from '../../components/errorpage/ErrorPage';
import { Paper } from '@mui/material';

class New extends Component{
    constructor(){
        super()
        this.state = ({
            is_loaded: false,
            athlete_name: "",
            workout_name: "",
            period: {"start": "", "end": ""},
            train_data: ["", "", "", "", ""],
            error_msg: null,
            counter: 0,
            excercise_list: "",
            error: false,
            modal: false,
            type: ""
        })
    }

    componentDidMount(){
        this.getDates()
        this.getWorkoutData()
    }

    async getWorkoutData(){
        try {
            // Chamada de API assíncrona utilizando a função listWorkouts()
            const response = await getMediaList();
            const data = response.data;
            this.setState({
                is_loaded : true
            })
            // Faça o que for necessário com os dados recebidos da API
            if (response.status === 200){
              this.setState({
                excercise_list: data
              })
            }
            else {
              this.setState({
                error: true
              })
            }
          } catch (error) {
            
            this.setState({
                is_loaded: true,
                error: true
            })
          }
    }

    handleDateChange(event, type){
        
        var period = this.state.period
        period[type] = event

        this.setState({
            period : period
        })
        
    }

    setAthleteName(event){

        const athlete_name = event.target.value

        this.setState({
            athlete_name: athlete_name
        })
    }

    setWorkoutNameName(event){
        const workout_name = event.target.value

        this.setState({
            workout_name: workout_name
        })
    }

    getDates(){
        var current_date = moment().toDate().getTime()
        var plus_one = moment(current_date).add(1, 'months')
      
        this.setState({
            period : {"start" : current_date, "end": plus_one}
        })
    }

    validadeFields(){
        const train_data = getStorageItem()
        const athlete_name = this.state.athlete_name
        const workout_name = this.state.workout_name

        if (train_data === "" || athlete_name === "" || workout_name === ""){
            this.setState({
                error_msg: "Preencha os campos necessários"
            })
            setTimeout(() => {
                this.setState({
                    error_msg: null
                })
              }, 2000)
            return false
        }
        else{
            return true
        }
    }

    sendTrainData = async () =>{
        const train_data = getStorageItem()
        const athlete_name = this.state.athlete_name
        const workout_name = this.state.workout_name

        const requestBody = {
            "athlete_name" : athlete_name,
            "workout_name" : workout_name,
            "workout_start": moment(this.state.period.start).format('DD/MM/YYYY'),
            "workout_end": moment(this.state.period.end).format('DD/MM/YYYY'),
            "train_data": JSON.stringify(train_data)
        }

        console.log(requestBody)

        this.setState({
            type: "working"
        })
        try {
            const response = await creatWorkout(requestBody);
            if (response.status === 200){
                this.setState({ 
                    type: "success"
                });
                setTimeout(() => {
                    // Sua função que será chamada após 3 segundos
                    window.location.href="/workoutlist";
                  }, 1000);
            }
            else {
                this.setState({ 
                    type: "error"
                });
                setTimeout(() => {
                    // Sua função que será chamada após 3 segundos
                    window.location.reload();
                  }, 1000);
            }
      
          } catch (error) {
            
            this.setState({
                error: true
            })
        }
    }

    getChildData(data){
        setStorageItem(data)
    }

    handleClose() {
        this.setState({
            modal: false
        })
    }

    getStatus(){
        if (this.state.type === "error"){
            return (
                <Fragment>
                <div style={{padding : 20, display : "flex", justifyContent : "center"}}>
                    <ErrorOutlineIcon sx={{color : "red"}} fontSize='large' />
                </div>
                <div style={{display : "flex", justifyContent : "center", marginTop : "-20px"}}>
                    <h2> Erro </h2>
                </div>
                </Fragment>
            );
        }
    
        if (this.state.type === "success"){
            return (
                <Fragment>
                <div style={{padding : 20, display : "flex", justifyContent : "center"}}>
                    <CheckCircleOutlineIcon sx={{color : "green"}} fontSize='large' />
                </div>
                <div style={{display : "flex", justifyContent : "center", marginTop : "-20px"}}>
                    <h2> Sucesso </h2>
                </div>
                </Fragment>
            );
        }
        if (this.state.type === "working"){
          return (
              <div style={{padding : 20, display : "flex", justifyContent : "center"}}>
                  <CircularProgress color="primary" />
              </div>
          );
      }
    }

    openModal(){
        const modal = this.validadeFields()
        this.setState({
            modal: modal,
            type: ""
        })
    }

    render(){

        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          };

        return(
            <Fragment>
                <Header />
                {this.state.error ? 
                <Paper>
                    <ErrorPage/>
                </Paper> :
                
                <Fragment>
                {this.state.is_loaded ?
                <div className='new-container'>
                    <div className='new-container-center'>
                    <div className='new-athlete-container'>
                        <div className='new-athlete-info'>
                            <Grid container spacing={4}>
                                <Box
                                    component="form"
                                    sx={{
                                        '& > :not(style)': { m: 1, width: '25ch' },
                                    }}
                                    noValidate
                                    autoComplete="off"
                                >
                                    <TextField
                                        required
                                        id="firstName"
                                        name="firstName"
                                        label="Nome"
                                        variant="standard"
                                        onChange={(e) => this.setAthleteName(e)}
                                    />
                                    <TextField
                                        required
                                        id="workoutName"
                                        name="workoutName"
                                        label="Nome do Treino"
                                        variant="standard"
                                        onChange={(e) => this.setWorkoutNameName(e)}
                                    />
                                </Box>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Grid item xs={3}>
                                        <MobileDatePicker
                                            label="Inicio"
                                            inputFormat="dd/MM/yyyy"
                                            value={this.state.period.start}
                                            onChange={(e) => this.handleDateChange(e, "start")}
                                            renderInput={(params) => <TextField {...params} />}
                                        />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <MobileDatePicker
                                                label="Fim"
                                                inputFormat="dd/MM/yyyy"
                                                value={this.state.period.end}
                                                onChange={(e) => this.handleDateChange(e, "end")}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                    </Grid>
                                </LocalizationProvider>
                            </Grid>
                        </div>
                    </div>
                    <div className='new-center-container'>
                        <TrainDrawer passChildData={this.getChildData} excercise_list={this.state.excercise_list} res={this.state.width ? this.state.width : 0} />
                    </div>
                    <div className='new-save-data'>
                        <Button 
                            size="large" 
                            variant="outlined" 
                            endIcon={<SaveIcon sx={{color: "rgba(249,88,54,1)"}} />}
                            onClick={() => this.openModal()}
                            >
                            Salvar
                        </Button>
                        {this.state.error_msg ? <Typography color="red">{this.state.error_msg}</Typography> : null}
                    </div>
                    </div>
                    {this.state.modal ? 
                        <Modal
                            open={this.state.modal}
                            onClose={() => this.handleClose()}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                        <Box sx={style}>
                            {this.state.type !== "" ? this.getStatus() :
                            
                            <Fragment>
                                <div style={{padding : 20}}>
                                    <Typography align='center' variant='subtitle1' color="black">
                                        Deseja salvar o treino?
                                    </Typography>
                                    <hr/>
                                    <Typography align='center' variant='body1' color="rgba(249,88,54,1)">
                                        {this.state.workout_name}
                                    </Typography>
                                    <Typography align='center' variant='body2' color="gray">
                                        {this.state.id}
                                    </Typography>
                                </div>
                                <Stack direction="row" spacing={5} sx={{justifyContent : 'center'}}>
                                    <Button variant="outlined" startIcon={<CloseIcon />} onClick={() => this.handleClose()}>
                                        Cancel
                                    </Button>
                                    <Button variant="contained" endIcon={<SaveIcon/>} onClick={() => this.sendTrainData(this.state.id)}>
                                        Salvar
                                    </Button>
                                </Stack>
                            </Fragment>
                            }
                        </Box>
                        </Modal>
                     : null }
                </div>
                :
            <PageLoader/>
            }
            </Fragment>
            }
            </Fragment>
        )
    }
}

export default New;