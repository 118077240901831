
const setVideoId = (url) =>{
    const watchRegex = /(?:\?v=)([\w-]+)/;
    const shortRegex = /(?:\.be\/|\/shorts\/)([\w-]+)/;
    const embedRegex = /(?:embed\/)([\w-]+)/;

    // Verificar o formato da URL e capturar o ID do vídeo
    let videoId = '';

    if (watchRegex.test(url)) {
      videoId = url.match(watchRegex)[1];
    } else if (shortRegex.test(url)) {
      videoId = url.match(shortRegex)[1];
    } else if (embedRegex.test(url)) {
      videoId = url.match(embedRegex)[1];
    }
    return videoId;
  }

export function WorkoutAdapter(data) {

    const propsData = JSON.parse(data)
        const newData = []
        const seriesMapping = {
        0 : "a",
        1 : "b",
        2 : "c",
        3 : "d",
        4 : "e"
    }

    propsData.forEach((i, idx) =>{
      if (i !== ""){
        newData.push({
          "name" : seriesMapping[idx],
          "list" : []
        })
        if (typeof(i) === "object"){
          i.forEach((j, jidx) =>{
            const exercise = j
            exercise["seq"] = (jidx +1)
            const url = j.url
            const urlId = setVideoId(url)
            exercise["url"] = (urlId)
            newData[idx]["list"].push(exercise)
          })
        }
      }
    });

    return newData
}