import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import YouTube from 'react-youtube';
import './workoutinfo.css';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
export default function WorkoutInfo() {
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const opts = {
      width: '100%',
      playerVars: {
        autoplay: 0,
      },
    }

  return (
    <div className='workoutinfo-container'>
      <Accordion >
        <AccordionSummary sx={{ backgroundColor : "#2e2e2e", color : "white"}}
          expandIcon={<ExpandMoreIcon sx={{color : "white"}} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <PriorityHighIcon fontSize='small'  sx={{color : "rgba(249,88,54,1)", marginTop: 0.5}} />
          <Typography variant="subtitle1">OBSERVAÇÕES GERAIS SOBRE OS GRUPOS MUSCULARES</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ minHeight : 250 }}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: '#000000' }}>
              <Tabs 
                value={value} 
                onChange={handleChange} 
                aria-label="exercise tab" 
                textColor='secondary'
                variant="scrollable"
                >
                <Tab label="TRÍCEPS" {...a11yProps(0)} />
                <Tab label="BÍCEPS" {...a11yProps(1)} />
                <Tab label="PUXAR" {...a11yProps(2)} />
                <Tab label="EMPURRAR" {...a11yProps(3)} />
                <Tab label="AGACHAMENTO" {...a11yProps(4)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Card sx={{ display: 'flex' }}>
                <Box sx={{ width : "100%", display: 'flex', flexDirection: 'column' }}>
                  <YouTube sx={{width : "100%"}} videoId="gC31W7AF-cc" opts={opts} />
                </Box>
              </Card>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box sx={{ width : "100%", display: 'flex', flexDirection: 'column' }}>
                <YouTube sx={{width : "100%"}} videoId="UiJGatq7_6A" opts={opts} />
              </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Card sx={{ display: 'flex' }}>
                <Box sx={{ width : "100%", display: 'flex', flexDirection: 'column' }}>
                  <YouTube sx={{width : "100%"}} videoId="c5RcbgGgyBg" opts={opts} />
                </Box>
              </Card>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Card sx={{ display: 'flex' }}>
                <Box sx={{ width : "100%", display: 'flex', flexDirection: 'column' }}>
                  <YouTube sx={{width : "100%"}} videoId="Kl87ctrBvcI" opts={opts} />
                </Box>
              </Card>
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Card sx={{ display: 'flex' }}>
                <Box sx={{ width : "100%", display: 'flex', flexDirection: 'column' }}>
                  <YouTube sx={{width : "100%"}} videoId="3uyZGJzcshM" opts={opts} />
                </Box>
              </Card>
            </TabPanel>
          </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}