import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AddIcon from "@mui/icons-material/Add";
import Toolbar from '@mui/material/Toolbar';
import TrainContent from '../traincontent/TrainContent';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


export default function SimpleTabs(props) {

  const [value, setValue] = React.useState(0);
  const [train, setTrain] = React.useState(0);

  const [train1, setTrain1] = React.useState("");
  const [train2, setTrain2] = React.useState("");
  const [train3, setTrain3] = React.useState("");
  const [train4, setTrain4] = React.useState("");
  const [train5, setTrain5] = React.useState("");
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    const train_data = [
      train1.train_data ? train1.train_data : "", 
      train2.train_data ? train2.train_data : "", 
      train3.train_data ? train3.train_data : "", 
      train4.train_data ? train4.train_data : "", 
      train5.train_data ? train5.train_data : ""]
    props.passChildData(train_data)
  });

  const addTrain = (event) => {
      if (train <= 3){
        setTrain(train +1)
        setValue(train +1)
      }
  }

  const delTrain = () =>{
    if (train >=1 ){
      setTrain(train -1)
      setValue(train -1)
    }

    if (train === 1){
      setTrain2("")
    }
    if (train === 2){
      setTrain3("")
    }
    if (value === 3){
      setTrain4("")
    }
    if (value === 4){
      setTrain5("")
    }
    
  }


  return (
    <div>
      <div style={{padding: 20, paddingBottom: 0}} >
          <Toolbar variant='dense'>
              <Tabs 
                value={value}
                onChange={handleChange}
                allowScrollButtonsMobile={true}
                scrollButtons={false}
                aria-label="tabs" 
                variant="scrollable"
                >
              <Tab label="Treino A" />
              {train >= 1 ? (<Tab label="Treino B" />) : null}
              {train >= 2 ? (<Tab label="Treino C" />) : null}
              {train >= 3 ? (<Tab label="Treino D" />) : null}
              {train >= 4 ? (<Tab label="Treino E" />) : null}
              </Tabs>
              <ButtonGroup aria-label="add del trains">
                <IconButton aria-label="add" onClick={() => addTrain()} >
                  <AddIcon />
                </IconButton>
                {train >= 1 ?
                <IconButton aria-label="delete" onClick={() => delTrain()} >
                  <RemoveIcon />
                </IconButton>
                : null }
              </ButtonGroup>
          </Toolbar>
        </div>
        <div style={{marginTop : -20}}>
        <TabPanel value={value} index={0} >
          <TrainContent index={value} passChildData={setTrain1} exercise_list={props.excercise_list} data={train1}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TrainContent index={value} passChildData={setTrain2} exercise_list={props.excercise_list} data={train2}/>
        </TabPanel>
        <TabPanel value={value} index={2} >
          <TrainContent index={value} passChildData={setTrain3} exercise_list={props.excercise_list} data={train3}/>
        </TabPanel>
        <TabPanel value={value} index={3} >
          <TrainContent index={value} passChildData={setTrain4} exercise_list={props.excercise_list} data={train4}/>
        </TabPanel>
        <TabPanel value={value} index={4} >
          <TrainContent index={value} passChildData={setTrain5} exercise_list={props.excercise_list} data={train5}/>
        </TabPanel>
        </div>
      </div>
  );
}