import * as React from 'react';
import Typography from '@mui/material/Typography';
import './workoutheadermobile.css';

export default function workoutHeaderMobile(props) {

  return (
        <div className='workoutheadermobile-container'>
            <div className='workoutheadermobile-left'>
            <Typography variant='subtitle1' sx={{ textTransform: "uppercase", background : 'linear-gradient(90deg, rgba(63,94,251,1) 15%, rgba(249,88,54,1) 100%)',  WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}> {props.athlete_name} </Typography>
            <Typography variant='subtitle2' sx={{color : 'rgb(255,255,255, 0.4)' }}> {props.workout_name} </Typography>
            </div>
            <div className='workoutheadermobile-right'>
                <div className='workoutheadermobile-date-left'>
                    <Typography align='center' sx={{ color : '#F95836' }}>INÍCIO</Typography>
                    <div className='workoutheadermobile-date-left-card'>
                        <Typography align='center'>{props.workout_start[0]}</Typography>
                        <hr/>
                        <Typography align='center'>{props.workout_start[1]}</Typography>
                    </div>
                </div>
                <div className='workoutheadermobile-date-right'>
                    <Typography align='center' variant='body1' sx={{ color : '#F95836' }}>FIM</Typography>
                    <div className='workoutheadermobile-date-right-card'>
                        <Typography align='center'>{props.workout_end[0]}</Typography>
                        <hr/>
                        <Typography align='center'>{props.workout_end[1]}</Typography>
                    </div>
                </div>
            </div>
        </div>
  );
}