import React, { Component } from 'react';
import { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import YouTube from 'react-youtube';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { WorkoutAdapter } from '../workoutadapter/WorkoutAdapter';
import './WorkoutListMobile.css';

export default class WorkoutListMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trainData: null,
      expanded: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    const newData = WorkoutAdapter(this.props.train_data);
    this.setState({ trainData: newData }, () => this.transformDataToIndex());
  }

  transformDataToIndex = () => {
    const { trainData } = this.state;
    if (!trainData) return;

    const keys = trainData.map(item => item.name);
    const dict_length = keys.map((item, idx) => trainData[idx]['list'].length);

    const idx = {};
    keys.forEach((el, index) => (idx[el] = Array(dict_length[index]).fill(false)));

    this.setState({ expanded: idx }, () => this.setState({ isLoading : false }));
  };

  handleExpandClick = (workout_name, train_seq) => {
    this.setState(prevState => {
      const exp = { ...prevState.expanded };
      exp[workout_name][train_seq] = !exp[workout_name][train_seq];
      return { expanded: exp };
    });
  };

  ExpandMore = styled(props => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  cardStyle = {
    backgroundColor: '#1e1e1e',
  };

  ytOpts = {
    height: '200px',
    width: '100%',
    playerVars: {
      autoplay: 0,
    },
  };

  render() {
    const { trainData, expanded } = this.state;

    return (
      <Fragment>
        {this.state.isLoading ? null :
          <div className='workoutlistmobile-container'>
            {trainData &&
              trainData.map((workout, widx) => (
                <div className='workoutlistmobile-train' key={widx}>
                  <hr className='hr-mobile' data-symbol={workout.name} />
                  {workout.list.map((train, idx) => (
                    <div className='workoutlistmobile-card' key={train.seq}>
                      <Card sx={this.cardStyle}>
                        <CardHeader
                          sx={{ marginBottom: -5 }}
                          avatar={
                            <Avatar
                              sx={{
                                border: '2px solid rgba(249,88,54,1)',
                                bgcolor: 'transparent',
                                maxWidth: 40,
                              }}
                              aria-label='recipe'
                            >
                              {train.seq}
                            </Avatar>
                          }
                          title={<p style={{ color: 'white' }}>{train.exercise.toUpperCase()}</p>}
                          subheader={
                            <React.Fragment>
                              <p className='mobile-workout-title'>
                                <span className='mobile-workout-info'>{train.series}</span> SERIE |{' '}
                                <span className='mobile-workout-info'>{train.rep}</span> REPETIÇÕES
                              </p>
                              <p className='mobile-workout-title'>
                                {' '}
                                <span className='mobile-workout-info'>{train.interval}</span>{' '}
                                DESCANSO | RITMO{' '}
                                <span className='mobile-workout-info'> {(train.rythm)}</span>{' '}
                              </p>
                            </React.Fragment>
                          }
                        />
                        <CardActions disableSpacing>
                          <this.ExpandMore
                            expand={expanded[workout.name][idx]}
                            onClick={() => this.handleExpandClick(workout.name, idx)}
                            aria-label='show more'
                          >
                            <ExpandMoreIcon sx={{ color: 'rgba(249,88,54,1)' }} />
                          </this.ExpandMore>
                        </CardActions>
                        <Collapse in={expanded[workout.name][idx]} timeout='auto' unmountOnExit>
                          <CardContent>
                            <React.Fragment>
                              {train.method ? (
                                <Fragment>
                                  <Typography variant='body2' color='rgba(255,255,255,0.8)' paragraph>
                                    MÉTODO
                                  </Typography>
                                  <Typography variant='body2' color='rgba(255,255,255,0.5)' paragraph>
                                    {String(train.method).toUpperCase()}
                                  </Typography>
                                </Fragment>
                              ) : null}
                              <YouTube videoId={train.url} opts={this.ytOpts} />
                            </React.Fragment>
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>
                  ))}
                  {/* Dummy Footer */}
                  <div className='workoutlistmobile-footer'></div>
                </div>
              ))}
          </div>
          }
      </Fragment>
    );
  }
}