const sessionKey = "personalWeb"

export function getStorageItem() {
    const data = sessionStorage.getItem(sessionKey)
    return JSON.parse(data)
}

export function setStorageItem(keyData){
    const data = JSON.stringify(keyData)
    const setData = sessionStorage.setItem(sessionKey, data)
    return setData
}

export function deleteStorageItem(keyData){
    sessionStorage.removeItem(sessionKey, keyData)
    return null
}

export function clearStorageItems(){
    sessionStorage.clear()
    return null
}