import { API, Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';

API.configure(awsconfig);

//export const prod_url = "http://localhost:3000"
export const prod_url = "https://app.fitnessconsultancy.fit"

const env = "prd"

async function getUserToken(){
  const user = await Auth.currentAuthenticatedUser()
  const token = await user.signInUserSession.idToken.jwtToken

  const options = {
     headers: {
       "Personaltoken": await token,
       "Content-Type": "application/json"
     },
    response: true
  };
  return options
}

export async function getMediaList() {

  const options = await getUserToken()
  return await API.get('PersonalApp', `/media`, options);
}

export async function editMediaList(reqbody) {
  const options = await getUserToken()
  return await API.post('PersonalApp', `/media`, {body : reqbody, response: true, headers : options.headers});
}

export async function createMediaList(reqbody) {
  const options = await getUserToken()
  return await API.put('PersonalApp', `/media`, {body : reqbody, response: true, headers : options.headers});
}

export async function deleteMediaList(id) {
  const options = await getUserToken()
  return await API.del('PersonalApp', `/media?id=${id}`, options);
}

export async function listWorkouts() {
  const options = await getUserToken()
  return await API.get('PersonalApp', `/workouts/workoutlist`, options);
}

export async function deleteWorkout(id) {
  const options = await getUserToken()
  return await API.del('PersonalApp', `/workouts?id=${id}`, options);
}

export async function creatWorkout(reqbody) {
  const options = await getUserToken()
  return await API.put('PersonalApp', `/workouts`, {body : reqbody, response: true, headers : options.headers});
}

export async function getWorkout(id) {
  return await API.get('PersonalApp', `/workouts?id=${id}`, {response: true});
}