import React, { Fragment } from 'react';
import {BrowserView, MobileView} from 'react-device-detect';
import DesktopAssessment from './DestkopAssessment';
import MobileAssessment from './MobileAssessment';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import PageLoader from '../../components/pageloader/PageLoader';
import ErrorPage from '../../components/errorpage/ErrorPage';
import { getWorkout } from '../../apis/apiservice';
import { isValidMD5  } from '../../components/hashvalidator/HashValidator';

const Assessment = () => {
    let { id } = useParams();
    const [data, setData] = useState(null);
    const [error, setError] = useState(false);
    const [noid, setNoId] = useState(null);
  
    useEffect(() => {
        const fetchData = async () => {
         try {
           const response = await getWorkout(id)
           var response_data = await response.data
           setData(response_data);

           setError(false);
         } 
         catch (error) 
         {
           setError(true);
         }
        }
        if (isValidMD5(id)){
            fetchData();
        } else {
            setError(true)
        }
    }, [id]);
  
    return (
        <Fragment>
            {noid ?
            null
            :
            <Fragment>
                {error ? 
                    <ErrorPage/>
                :
                <Fragment>
                    <Fragment>
                        {data ? 
                            <Fragment>
                                <BrowserView>
                                    <DesktopAssessment data={data}/>
                                </BrowserView>
                                <MobileView>
                                    <MobileAssessment data={data}/>
                                </MobileView>
                            </Fragment>
                            :
                            <PageLoader />
                        }
                    </Fragment>
                </Fragment>
                }
            </Fragment>
            }
        </Fragment>
    );
  };
export default Assessment;