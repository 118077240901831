import React from 'react';
import { Fragment } from 'react';
import Header from '../../components/header/Header';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import './workoutlist.css';
import Moment from 'moment';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteWorkout, listWorkouts, prod_url} from '../../apis/apiservice';
import PageLoader from '../../components/pageloader/PageLoader';
import ErrorPage from '../../components/errorpage/ErrorPage';
import { Paper } from '@mui/material';
import Modal from '@mui/material/Modal';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


class WorkoutList extends React.Component{
  constructor(props){
      super(props);
      this.state = {
        isLoading: true,
        error: false,
        modal: false,
        url: "",
        status: "",
        type: "",
      }
  }

  async componentDidMount() {
    try {
      // Chamada de API assíncrona utilizando a função listWorkouts()
      const response = await listWorkouts();
      console.log("RESPONSE:", response)
      const data = response.data;
      this.setState({
        isLoading : false
      })
      // Faça o que for necessário com os dados recebidos da API
      if (response.status === 200){
        this.setData(data)
      }
      else {
        this.setState({
          type: "error"
        })
      }
    } catch (error) {
      
      this.setState({
        isLoading: false,
        error: true
      })
    }
  }

  setData(data){

    const columns = [
      { 
        field: 'id', 
        headerName: 'ID', 
        width: 100,
        align: "center",
        renderCell: (params) => 
          <Tooltip title={params.row.id}>
            <IconButton sx={{color : "rgba(249,88,54,1)"}}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
      },
      { 
        field: 'workout_name', 
        headerName: 'Treino', 
        width: 200,
        align: "left",
        renderCell: (params) => 
          <a className='workoutlist-name' href={params.row.url} target='_blank' rel="noreferrer">{params.row.workout_name}</a>
      },
      {
        field: 'nome',
        headerName: 'Nome',
        width: 150,
        editable: false
      },
      {
        field: 'criado_em',
        headerName: 'Criado em',
        type: 'date',
        width: 190,
        editable: false,
      },
      {
        field: 'inicio',
        headerName: 'Inicio do Treino',
        type: 'date',
        width: 190,
        editable: false,
      },
      {
        field: 'fim',
        headerName: 'Fim do Treino',
        type: 'date',
        width: 170,
        editable: false,
      },
      {
        field: 'vencido',
        headerName: 'Vencido',
        type: 'string',
        width: 130,
        editable: false,
        renderCell: (params) => (
          (params.row.vencido) === "Sim" ? 
            <p className='workoutlist-vencido'> {params.row.vencido} </p>
          :
            <p> {params.row.vencido} </p>
        )
      }, 
      {
        field: 'delete',
        headerName: 'Remover',
        type: 'icon',
        width: 120,
        editable: false,
        renderCell: (params) => (
            <Fragment>
              <IconButton onClick={() => this.handleDelete(params.row.id, params.row.workout_name)}>
                <DeleteIcon/>
              </IconButton>
            </Fragment>
        )
      }, 
      {
        field: 'share',
        headerName: 'Copy URL',
        type: 'icon',
        width: 120,
        editable: false,
        renderCell: (params) => (
          <Fragment>
          <Tooltip title={params.row.url}>
            <IconButton onClick={() => this.handlecopy(params.row.url)}>
              <ContentCopyIcon/>
            </IconButton>
          </Tooltip>
        </Fragment>
        )
      }
    ];

    var rows = []
    data.forEach(element => {
      rows.push({
        id : element.id,
        workout_name: element.workout_name,
        url : (prod_url + "/assessment/" + element.id),
        nome: element.athlete_name,
        criado_em : element.created_at,
        inicio: element.workout_start,
        fim : element.workout_end
      })
    });

    rows.map(i => {
      const workout_end = Moment(i.fim, 'DD/MM/YYYY');

      if (workout_end.isBefore(Moment(), 'day')) {
        // Se a data de fim for posterior à data atual, defina a variável "expirado" como true
        i["vencido"] = "Sim"
      } else {
        // Caso contrário, a variável "expirado" permanece como false
        i["vencido"] = "Não"
      }

      const startDateMoment = Moment(i.inicio, 'DD/MM/YYYY');
      const endDateMoment = Moment(i.fim, 'DD/MM/YYYY');

      i["inicio"] = startDateMoment.format('DD/MM/YYYY');
      i["fim"] = endDateMoment.format('DD/MM/YYYY');

      return null
    })


    this.setState({
      columns: columns,
      rows: rows
    })

  }

  async handlecopy(url){
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(url);
    } else {
      return document.execCommand('copy', true, url);
    }
  }

  handleDelete(id, workout_name){
    this.setState({
      modal: true,
      id: id,
      workout_name: workout_name
    })
  }

  handleClose(){
    this.setState({
        modal: false
    })
  }

  deleteSelection(id){

    const idd = id

    this.setState({
        type: "working"
    }, async () => {
        const res = await deleteWorkout(idd)
        if (res.status === 200){
            this.setState({
                type: "success"
            }, () => setTimeout(() =>window.location.reload(), 2000))
        } else {
            this.setState({
                type: "error"
            })
        }
    })
  }

  getStatus(){
    if (this.state.type === "error"){
        return (
            <Fragment>
            <div style={{padding : 20, display : "flex", justifyContent : "center"}}>
                <ErrorOutlineIcon sx={{color : "red"}} fontSize='large' />
            </div>
            <div style={{display : "flex", justifyContent : "center", marginTop : "-20px"}}>
                <h2> Erro </h2>
            </div>
            </Fragment>
        );
    }

    if (this.state.type === "success"){
        return (
            <Fragment>
            <div style={{padding : 20, display : "flex", justifyContent : "center"}}>
                <CheckCircleOutlineIcon sx={{color : "green"}} fontSize='large' />
            </div>
            <div style={{display : "flex", justifyContent : "center", marginTop : "-20px"}}>
                <h2> Sucesso </h2>
            </div>
            </Fragment>
        );
    }
    if (this.state.type === "working"){
      return (
          <div style={{padding : 20, display : "flex", justifyContent : "center"}}>
              <CircularProgress color="primary" />
          </div>
      );
  }
  }

  render(){

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 500,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
    };

    return (
      <Fragment>
      <Header/>
        {this.state.error ? 
        <Paper>
          <ErrorPage/> 
        </Paper>
        : 
        <Fragment>
          {this.state.isLoading ? <PageLoader/> :
            <Fragment>
            {this.state.rows ?
              <div className='workoutlist-container'>
                <Box sx={{ height: 650, width: '80%', backgroundColor: "white", margin: "auto", display: "flex" }}>
                  <DataGrid
                    rows={this.state.rows}
                    columns={this.state.columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    disableColumnSelector
                    disableColumnMenu
                    disableColumnFilter
                    disableDensitySelector
                  />
                </Box>
              </div>
              :
              <div></div>
            }
            </Fragment>
          }
        </Fragment>
        }
        <Modal
          open={this.state.modal}
          onClose={() => this.handleClose()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
          <Box sx={style}>
            {this.state.type !== "" ? this.getStatus() :
             
              <Fragment>
                <div style={{padding : 20}}>
                    <Typography align='center' variant='subtitle1' color="black">
                        Tem certeza que deseja Excluir?
                    </Typography>
                    <hr/>
                    <Typography align='center' variant='body1' color="black">
                        {this.state.workout_name}
                    </Typography>
                    <Typography align='center' variant='body2' color="gray">
                        {this.state.id}
                    </Typography>
                </div>
                <Stack direction="row" spacing={5} sx={{justifyContent : 'center'}}>
                    <Button variant="outlined" startIcon={<CloseIcon />} onClick={() => this.handleClose()}>
                        Cancel
                    </Button>
                    <Button variant="contained" endIcon={<DeleteIcon/>} onClick={() => this.deleteSelection(this.state.id)}>
                        Excluir
                    </Button>
                </Stack>
              </Fragment>
            }
          </Box>
        </Modal>
      </Fragment>
      )
    }

}

export default WorkoutList;