import React from 'react';
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {Login} from './pages/login/Login';
import New from './pages/new/New';
import WorkoutList from './pages/workoutlist/WorkoutList';
import Landing from './pages/landing/Landing';
import Media from './pages/media/Media';
import Assessment from './pages/assessment/Assessment';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import { Amplify } from 'aws-amplify'
import { RequireAuth } from './pages/login/RequireAuth';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

let theme = createTheme({
  palette: {
    primary: {
      main: "rgba(249,88,54,1)",
    },
    secondary: {
      main: "#1e1e1e",
    },
  },
});

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);



root.render(
  <Authenticator.Provider>
  <BrowserRouter forceRefresh={true}>
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" exact={true} element={
          <RequireAuth>
            <Landing/>
          </RequireAuth>} />
        <Route path="/login" element={<Login/>} />
        
        <Route path="/assessment/:id" element={<Assessment/>}/>
        <Route path="/new" element={
          <RequireAuth>
            <New/>
          </RequireAuth>
        }/>
        <Route path="/workoutlist" element={
          <RequireAuth>
            <WorkoutList/>
        </RequireAuth>
        }/>
        <Route path="/app" element={
          <RequireAuth>
            <Landing/>
          </RequireAuth>
          }/>
        <Route path="/media" element={
          <RequireAuth>
            <Media/>
          </RequireAuth>
        }/>
      </Routes>
    </ThemeProvider>
  </BrowserRouter>
  </Authenticator.Provider>
);