import React from 'react';
import { Fragment, Component } from 'react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import {editMediaList, deleteMediaList } from '../../apis/apiservice';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Typography } from '@mui/material';


class ActionEdit extends Component{
    constructor(props){
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.saveEditSelection = this.saveEditSelection.bind(this);
        this.deleteSelection = this.deleteSelection.bind(this);
        this.state = {
            id : "",
            name: "",
            category: "",
            url: "",
            modal: false,
            type: null,
            http_data : ""
        }
    }

    componentDidMount(){
        this.setState({
            id : this.props.id,
            name : this.props.name,
            category : this.props.category,
            url : this.props.url,
            modal: false,
            type: null
        })
    }

    openModal(row, type){
        console.log(type)
        this.setState({
            modal: true,
            id : this.props.id,
            name : this.props.name,
            category : this.props.category,
            url : this.props.url,
            type: type
        })
    };

    handleInputChange(event, field) {

        if(field === "name"){
            this.setState({ name: event.target.value });
        }
        if(field === "category"){
            this.setState({ category: event.target.value });
        }
        if(field === "url"){
            this.setState({ url: event.target.value });
        }
      }

      deleteSelection(id){

        const idd = id
        console.log(id)

        this.setState({
            type: "working"
        }, async () => {
            const res = await deleteMediaList(idd)
            console.log(res)
            if (res.status === 200){
                this.setState({
                    type: "success"
                }, () => setTimeout(() =>window.location.reload(), 1000))
            } else {
                this.setState({
                    type: "error"
                })
            }
        })
    
      }

      saveEditSelection(){
        
        const id = this.state.id
        const name = this.state.name
        const category = this.state.category
        const url = this.state.url

        const json = {
            "id" : id,
            "name" : name,
            "category" : category,
            "url" : url
        }
        this.setState({
            type: "working"
        }, async () => {
            const res = await editMediaList(json)
            console.log(res)
            if (res.status === 200){
                this.setState({
                    type: "success"
                }, () => setTimeout(() =>window.location.reload(), 1000))
            } else {
                this.setState({
                    type: "error"
                })
            }
        })

      }

    getModalContent(){
        if (this.state.type === "edit"){
            return(
                <Fragment>
                    <div style={{padding : 20}}>
                        <TextField fullWidth  label="Nome" variant="standard" defaultValue={this.props.name} sx={{padding : 1}} onChange={(e) => this.handleInputChange(e, "name")}/>
                        <TextField fullWidth  label="Categoria" variant="standard" defaultValue={this.props.category} sx={{padding : 1}} onChange={(e) => this.handleInputChange(e, "category")}/>
                        <TextField fullWidth  label="URL" variant="standard" defaultValue={this.props.url} sx={{padding : 1}} onChange={(e) => this.handleInputChange(e, "url")}/>
                    </div>
                    <Stack direction="row" spacing={5} sx={{justifyContent : 'center'}}>
                        <Button variant="outlined" startIcon={<CloseIcon />} onClick={() => this.handleClose()}>
                            Cancel
                        </Button>
                        <Button variant="contained" endIcon={<SaveIcon/>} onClick={() => this.saveEditSelection()}>
                            Save
                        </Button>
                    </Stack>
                </Fragment>
            )
        }
        if (this.state.type === "delete"){
            return(
                <Fragment>
                    <div style={{padding : 20}}>
                        <Typography align='center' variant='subtitle1' color="black">
                            Tem certeza que deseja Excluir?
                        </Typography>
                        <hr/>
                        <Typography align='center' variant='body2' color="gray">
                            {this.state.name}
                        </Typography>
                    </div>
                    <Stack direction="row" spacing={5} sx={{justifyContent : 'center'}}>
                        <Button variant="outlined" startIcon={<CloseIcon />} onClick={() => this.handleClose()}>
                            Cancel
                        </Button>
                        <Button variant="contained" endIcon={<DeleteIcon/>} onClick={() => this.deleteSelection(this.state.id)}>
                            Excluir
                        </Button>
                    </Stack>
                </Fragment>
            )
            
        }

        if (this.state.type === "error"){
            return (
                <Fragment>
                <div style={{padding : 20, display : "flex", justifyContent : "center"}}>
                    <ErrorOutlineIcon sx={{color : "red"}} fontSize='large' />
                </div>
                <div style={{display : "flex", justifyContent : "center", marginTop : "-20px"}}>
                    <h2> Erro </h2>
                </div>
                </Fragment>
            );
        }

        if (this.state.type === "success"){
            return (
                <Fragment>
                <div style={{padding : 20, display : "flex", justifyContent : "center"}}>
                    <CheckCircleOutlineIcon sx={{color : "green"}} fontSize='large' />
                </div>
                <div style={{display : "flex", justifyContent : "center", marginTop : "-20px"}}>
                    <h2> Sucesso </h2>
                </div>
                </Fragment>
            );
        }

        if (this.state.type === "working"){

            return (
                <div style={{padding : 20, display : "flex", justifyContent : "center"}}>
                    <CircularProgress color="primary" />
                </div>
            );
        }
    }

    handleClose(){
        this.setState({
            modal: false
        })
    }

    render () {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          };
        return (
            <Fragment>
                <Stack direction="row" spacing={1}>
                    <IconButton color="secondary" aria-label="edit" onClick={() => this.openModal(this.state.row, "edit")}>
                        <EditIcon fontSize="small" />
                    </IconButton>
                    <IconButton aria-label="delete" onClick={() => this.openModal(this.state.row, "delete")}>
                        <DeleteIcon fontSize="small"/>
                    </IconButton>
                </Stack>
                {this.state.modal?
                    <Modal
                        open={this.state.modal}
                        onClose={() => this.handleClose()}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            {this.getModalContent()}
                        </Box>
                    </Modal>
                :
                null}
          </Fragment>
        )
    }
}

export default ActionEdit