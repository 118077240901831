import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { createMediaList } from '../../apis/apiservice';

export default function FloatingAction() {

    const [modalOpened, setModal] = useState(false);
    const [url, setUrl] = useState("")
    const [name, setName] = useState("")
    const [category, setCategory] = useState("")
    const [isloading, setLoading] = useState(false)
    const [sendStatus, setStatus] = useState(null)
    const [afterCall, setAfterCall] = useState(false)
    const [fieldsMissing, setFieldsMissing] = useState(false)
    
    const style = {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 500,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          };
          
    function handleClose(){
        setModal(false)
    }

    function openModal(){
        setModal(true)
        console.log("add MEDIA")
    }

    function handleInputChange(e, field){

        if(field === "name"){
            setName({ name: e.target.value });
        }
        if(field === "category"){
            setCategory({ category: e.target.value });
        }
        if(field === "url"){
            setUrl({ url: e.target.value });
        }
    }

    function validateFields(){

        if ((url.url.length > 7) && (name.name.length > 0) && (category.category.length > 0)){
            return true
        } else{
            return false
        }
    }

    async function fetchData(body) {
        const res = await createMediaList(body)
        console.log(res)
        if (res.status === 200){
            setLoading(false);
            setAfterCall(true);
            setStatus("success");
            setTimeout(() =>window.location.reload(), 1000);
        } else {
            setLoading(false)
            setAfterCall(true)
            setStatus("error")
            setTimeout(() =>window.location.reload(), 1000);
        }
    }

    function saveMedia(){
        setFieldsMissing(false)
        
        const validFields = validateFields()

        if (validFields) {

            const body = {
                'name' : name.name,
                'url' : url.url,
                'category' : category.category
            }

            setLoading(true)

            fetchData(body)

        } else {
            setFieldsMissing(true)
        }
    }

    function getStatus(){
        if (sendStatus === "success"){
            return (
                <React.Fragment>
                <div style={{padding : 20, display : "flex", justifyContent : "center"}}>
                    <CheckCircleOutlineIcon sx={{color : "green"}} fontSize='large' />
                </div>
                <div style={{display : "flex", justifyContent : "center", marginTop : "-20px"}}>
                    <h2> Sucesso </h2>
                </div>
                </React.Fragment>
            );
        }
        if (sendStatus === "error"){
            return (
                <React.Fragment>
                <div style={{padding : 20, display : "flex", justifyContent : "center"}}>
                    <ErrorOutlineIcon sx={{color : "red"}} fontSize='large' />
                </div>
                <div style={{display : "flex", justifyContent : "center", marginTop : "-20px"}}>
                    <h2> Erro </h2>
                </div>
                </React.Fragment>
            );
        }
        if (sendStatus === ""){
            return null
        }
    }

    return (
        <React.Fragment>
            {modalOpened ? 
                <Modal
                    open={modalOpened}
                    onClose={() => handleClose()}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        { isloading ? 
                            <div style={{padding : 20, display : "flex", justifyContent : "center"}}>
                                <CircularProgress color="primary" />
                            </div>
                        :
                        <React.Fragment>
                            {afterCall ? 
                            getStatus()
                            :
                            <React.Fragment>
                                <div style={{padding : 20}}>
                                    <TextField fullWidth  label="Nome" variant="standard" sx={{padding : 1}} onChange={(e) => handleInputChange(e, "name")}/>
                                    <TextField fullWidth  label="Categoria" variant="standard"  sx={{padding : 1}} onChange={(e) => handleInputChange(e, "category")}/>
                                    <TextField fullWidth  label="URL" variant="standard"  sx={{padding : 1}} onChange={(e) => handleInputChange(e, "url")}/>
                                    {fieldsMissing ? <p style={{color : "red"}}>Preencha todos os campos</p> : null}
                                </div>
                                <Stack direction="row" spacing={5} sx={{justifyContent : 'center'}}>
                                    <Button variant="outlined" startIcon={<CloseIcon />} onClick={() => handleClose()}>
                                        Cancel
                                    </Button>
                                    <Button variant="contained" endIcon={<SaveIcon/>} onClick={() => saveMedia()}>
                                        Save
                                    </Button>
                                </Stack>
                            </React.Fragment>
                            }
                        </React.Fragment>
                        }
                    </Box>
                </Modal>
                :
                 null
            }
            <Box sx={{ '& > :not(style)': { m: 1 }, display: "flex", justifyContent : "end"}}>
                <Fab color="primary" aria-label="add" onClick={() => openModal()}>
                    <AddIcon />
                </Fab>
            </Box>
      </React.Fragment>
    );
  }