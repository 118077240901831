import * as React from 'react';
import { Fragment } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import YouTube from 'react-youtube';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { useState, useEffect } from 'react';
import { WorkoutAdapter } from '../workoutadapter/WorkoutAdapter';
import './workoutlist.css';

export default function WorkoutList(props) {
  const [expanded, setExpanded] = useState(false);
  const [trainData, setTrainData] = useState(null);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };



  const opts = {
    height: '390',
    width: '100%',
    playerVars: {
      autoplay: 0,
    },
  }

  const tableSx = {
    maxWidth : 60, 
    minWidth : 60, 
    borderColor : 'transparent',
  }

  const setVideoId = (url) =>{
    const watchRegex = /(?:\?v=)([\w-]+)/;
    const shortRegex = /(?:\.be\/)([\w-]+)/;
    const embedRegex = /(?:embed\/)([\w-]+)/;

    // Verificar o formato da URL e capturar o ID do vídeo
    let videoId = '';

    if (watchRegex.test(url)) {
      videoId = url.match(watchRegex)[1];
    } else if (shortRegex.test(url)) {
      videoId = url.match(shortRegex)[1];
    } else if (embedRegex.test(url)) {
      videoId = url.match(embedRegex)[1];
    }
    console.log(videoId)
    return videoId;
  }

  useEffect(() => {
    
    const newData = WorkoutAdapter(props.train_data)
    setTrainData(newData)

  }, []);

  return (
    <Fragment>
      {trainData ? 
      <div className='table-side'>
          {trainData.map((workout) => (
            <Fragment>
            <TableContainer component={Paper} key={workout.name} >
              <Table sx={{ minWidth: 650 }} aria-label="data header">
                <TableRow sx={{ backgroundColor : "#2e2e2e", color : "white" }}>
                  <TableCell sx={{maxWidth : 10, minWidth : 10, borderBottom : "1px solid rgba(249,88,54,1)"}} align="left"> <Avatar sx={{ bgcolor: "#1e1e1e", color: "rgba(249,88,54,1)" }}>{workout.name}</Avatar> </TableCell>
                  <TableCell sx={{maxWidth : 60, minWidth : 60, borderBottom : "1px solid rgba(249,88,54,1)"}} align="left">Sequência</TableCell>
                  <TableCell sx={{maxWidth : 150, minWidth : 150, borderBottom : "1px solid rgba(249,88,54,1)"}} align="left">Exercício</TableCell>
                  <TableCell sx={{maxWidth : 60, minWidth : 60, borderBottom : "1px solid rgba(249,88,54,1)"}} align="center">Séries</TableCell>
                  <TableCell sx={{maxWidth : 60, minWidth : 60, borderBottom : "1px solid rgba(249,88,54,1)"}} align="center">Repetições</TableCell>
                  <TableCell sx={{maxWidth : 60, minWidth : 60, borderBottom : "1px solid rgba(249,88,54,1)"}} align="center">Descanso</TableCell>
                  <TableCell sx={{maxWidth : 60, minWidth : 60, borderBottom : "1px solid rgba(249,88,54,1)"}} align="center">Rítmo</TableCell>
                  <TableCell sx={{maxWidth : 60, minWidth : 60, borderBottom : "1px solid rgba(249,88,54,1)"}} align="center">Método</TableCell>
                </TableRow>
              </Table>
            </TableContainer>
            {workout.list.map((train) => (
              <Fragment>
                <Accordion expanded={expanded === (workout.name + train.seq) } onChange={handleChange((workout.name + train.seq))} key={(workout.name + train.seq)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{color: "rgba(249,88,54,1)"}} />}
                      aria-controls={train.seq}
                      id={(workout.name + train.seq)}
                      sx={{ backgroundColor : "#2e2e2e" }}
                    >
                    <Table sx={{ minWidth: 650 }} aria-label="data table" size='small' >
                      <TableRow sx={{ backgroundColor : "#2e2e2e", color : "white" }}>
                        <TableCell sx={{maxWidth : 10, minWidth : 10, borderColor : 'transparent' }} align="left"></TableCell>
                        <TableCell sx={tableSx} align="left">{train.seq}</TableCell>
                        <TableCell sx={{maxWidth : 170, minWidth : 170, borderColor : 'transparent'}} align="left">{train.exercise}</TableCell>
                        <TableCell sx={tableSx} align="center">{train.series}</TableCell>
                        <TableCell sx={tableSx} align="center">{train.rep}</TableCell>
                        <TableCell sx={tableSx} align="center">{train.interval}</TableCell>
                        <TableCell sx={tableSx} align="right">{train.rythm}</TableCell>
                        <TableCell sx={tableSx} align="right">
                          {train.method.length > 1 ? 
                            <div>
                              <Tooltip title={train.method}>
                                <IconButton sx={{color : "white"}}>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                            :
                            <div></div>
                          }
                        </TableCell>
                      </TableRow>
                    </Table>

                    </AccordionSummary>
                    <AccordionDetails sx={{backgroundColor : "#000000"}}>
                      <div className='workoutlist-acordion-container'>
                        <Box>
                          <Typography align="center" variant='body2' color="rgba(249,88,54,1)" sx={{padding : 1}}><InfoIcon color="red" sx={{marginBottom : -0.8}}/> {train.method}</Typography>
                          <YouTube videoId={train.url} opts={opts} />
                        </Box>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </Fragment>
                ))
              }
              </Fragment>
          ))}

      </div>
      :
      null
      }
    </Fragment>
  );
}