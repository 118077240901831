import React from 'react';
import { Component, Fragment } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { styled, lighten, darken } from '@mui/system';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import YouTube from 'react-youtube';
import './TrainContent.css'

class TrainContent extends Component{
    constructor(props){
        super(props);
        this.state = ({
            index : null,
            is_loaded: false,
            rows_count: 1,
            input_value: "",
            modal_open: false,
            tmp_value: [["","","","",""]],
            modal_nome: "",
            modal_url: "",
            train_data: [
                { 
                    "exercise" : "",
                    "url" : "",
                    "series" : "",
                    "rep" : "",
                    "interval" : "",
                    "rythm" : "",
                    "method" : ""
                }
            ]
        })
    }

    componentDidMount(){
        this.setState({
            index : this.props.index,
            exercises: this.props.exercise_list,
        }, () =>
            this.setChildData()
            )
    }

    setChildData(){
        // Recebe dados do parent
        var data = this.props.data
        if (data !== ""){
            this.setState({
                "rows_count": data.rows_count,
                "train_data": data.train_data,
                "tmp_value" : data.tmp_value,
            })
        }
        this.setState({
            "is_loaded" : true
        })
    }

    functionHandler = () => {
        // Passa dados para o parent

        this.props.passChildData({
            "rows_count" : this.state.rows_count,
            "train_data" : this.state.train_data,
            "tmp_value" : this.state.tmp_value
        });
        
    }

    addRow(){

        const default_values = { 
            "exercise" : "",
            "url" : "",
            "series" : "",
            "rep" : "",
            "interval" : "",
            "rythm" : "",
            "method" : ""
        }

        const new_tmp_array = ["","","","",""]
        var tmp_value = this.state.tmp_value
        tmp_value.push(new_tmp_array)

        const state_data = this.state.train_data
        var new_data = state_data
        new_data.push(default_values)

        this.setState({
            rows_count: this.state.rows_count +1,
            train_data : new_data,
            tmp_value : tmp_value
        })

    }

    removeRow(index){

        const state_data = this.state.train_data
        var new_data = state_data
        new_data.splice(index, 1)

        const tmp_value = this.state.tmp_value
        var new_tmp = tmp_value
        new_tmp.splice(index, 1)

        this.setState({
            rows_count: this.state.rows_count -1,
            train_data: new_data,
            tmp_value : new_tmp
        })
    }

    setExercise(event, index, option){

        //Função para tratar os dados selecionados do Autocomplete de exercícios

        const exercise = option
        console.log("EXERCISE", exercise)

        if (exercise !== null){

            this.setState({
                input_value : ""
            })
            
            const train_data = this.state.train_data
            train_data[index]["exercise"] = exercise.name
            train_data[index]["url"] = exercise.url
            
            this.setState({
                train_data : train_data
            })
        } else {
            const train_data = this.state.train_data
            train_data[index]["exercise"] = ""
            train_data[index]["url"] = ""
            this.setState({
                input_value : "",
                train_data: train_data
            })
        }

    }


    setData = (id, index, position) => {

        const tmp_value = this.state.tmp_value[index][position]
        const new_tmp = this.state.tmp_value
        new_tmp[index][position] = ""

        const train_data = this.state.train_data
        train_data[index][id] = tmp_value

        this.setState({
            train_data : train_data,
            tmp_value : new_tmp
        })

        this.functionHandler()
    }

    setTmpData = (event, id, index, position) => {

        var tmp_data = event.target.value
        var tmp_value = this.state.tmp_value

        tmp_value[index][position] = tmp_data

        this.setState({
            tmp_value : tmp_value
        })
    }

    handleOnFocus = (index, position, id) =>{



        var train_data = this.state.train_data

        var tmp_value = this.state.tmp_value
        tmp_value[index][position] = train_data[index][id]

        train_data[index][id] = ""

        this.setState({
            tmp_value : tmp_value,
            train_data : train_data
        })

    }

    handleAutocompleteInputChange(event){

        // Trata o input do formulário de Autocomplete
        if (event !== null){
            var text = event.target.value
            this.setState({
                input_value : text
            })
        }
    }

    closeModal(){
        this.setState({
            modal_open : false,
            modal_nome: "",
            modal_url: ""
        })
    }

    goToURL(url, exercicio){

        console.log("URL", url)
        console.log("exercicio", exercicio)

        var video_id = url.replace("https://youtu.be/", "")
        video_id = video_id.replace(" ", "")
        this.setState({
            modal_open : true,
            modal_nome: exercicio,
            modal_url: video_id
        })
    }


    render(){

        const GroupHeader = styled('div')(({ theme }) => ({
            position: 'sticky',
            top: '-8px',
            padding: '4px 10px',
            color: theme.palette.primary.main,
            backgroundColor:
              theme.palette.mode === 'light'
                ? lighten(theme.palette.primary.light, 1)
                : darken(theme.palette.primary.main, 1),
          }));

          const GroupItems = styled('ul')({
            padding: 0,
          });

        return(
            <Fragment>
                <TableContainer component={Paper}>
                <Table aria-label="excercises table" >
                    <TableHead>
                        <TableRow key="header" >
                            <TableCell></TableCell >
                            <TableCell>Sequência</TableCell>
                            <TableCell align="center">Exercício</TableCell>
                            <TableCell align="center">URL</TableCell>
                            <TableCell align="center">Séries</TableCell>
                            <TableCell align="center">Repetições</TableCell>
                            <TableCell align="center">Descanso</TableCell>
                            <TableCell align="center">Ritmo</TableCell>
                            <TableCell align="center">Método/Técnica</TableCell>
                        </TableRow>
                    </TableHead>
                    {this.state.is_loaded ?
                        <TableBody>
                            {this.state.train_data.map((row, index) => (
                                <Fragment>
                                    <TableRow
                                        key={row.seq}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >

                                        {/* Botão Remover */}
                                        <TableCell component="th" scope="row" sx={{maxWidth : 30}}>
                                            {index === 0 ? null :
                                            <IconButton aria-label="delete" onClick={() => this.removeRow(index)}>
                                                <DeleteIcon />
                                            </IconButton> 
                                            }
                                        </TableCell>

                                        {/* Sequência */}
                                        <TableCell component="th" scope="row" size="small" sx={{maxWidth : 30}}>
                                            <Typography> {index +1 } </Typography>
                                        </TableCell>

                                        {/* Lista de exercícios */}
                                        <TableCell align="right">
                                            <Autocomplete
                                                id="excercise list"
                                                options={this.state.exercises.sort((a, b) => a.category.localeCompare(b.category))}
                                                getOptionLabel={(option) => option.name}
                                                groupBy={(option) => option.category}
                                                handleHomeEndKeys
                                                onChange={(e, option) => this.setExercise(e, index, option)}
                                                defaultValue={row.exercise ? row.exercise : null}
                                                inputValue={row.exercise ? row.exercise : this.state.input_value}
                                                onInputChange={(e) => this.handleAutocompleteInputChange(e, index)}
                                                sx={{ width: 300 }}
                                                renderInput={(params) => <TextField {...params} />}
                                                renderGroup={(params) => (
                                                    <li key={params.id}>
                                                      <GroupHeader>{params.group}</GroupHeader>
                                                      <GroupItems>{params.children}</GroupItems>
                                                    </li>
                                                  )}
                                            />
                                        </TableCell>
                                        {/* URL */}
                                        <TableCell align="right" sx={{maxWidth : 30}}>
                                            {row.url ? 
                                                <IconButton aria-label="url" onClick={() => this.goToURL(row.url, row.exercise)}>
                                                    <LinkIcon />
                                                </IconButton>
                                            :
                                            null}
                                        </TableCell>
                                        {/* Series */}
                                        <TableCell align="right" sx={{maxWidth : 30}}>
                                            <TextField
                                                value={row.series === "" ? this.state.tmp_value[index][0] : row.series}
                                                id="series"
                                                onBlur={(e) => this.setData("series", index, 0)}
                                                onFocus={() => this.handleOnFocus(index, 0, "series")}
                                                onChange={(e) => this.setTmpData(e, "series", index, 0)}
                                            />
                                        </TableCell>
                                        {/* Repetições */}
                                        <TableCell align="right" sx={{maxWidth : 30}}>
                                            <TextField
                                                value={row.rep === "" ? this.state.tmp_value[index][1] : row.rep}
                                                id="rep"
                                                onBlur={(e) => this.setData("rep", index, 1)}
                                                onFocus={() => this.handleOnFocus(index, 1, "rep")}
                                                onChange={(e) => this.setTmpData(e, "rep", index, 1)}
                                            />
                                        </TableCell>
                                        {/* Intervalos */}
                                        <TableCell align="right" sx={{maxWidth : 30}}>
                                            <TextField
                                                value={row.interval === "" ? this.state.tmp_value[index][2] : row.interval}
                                                id="interval"
                                                onBlur={(e) => this.setData("interval", index, 2)}
                                                onFocus={() => this.handleOnFocus(index, 2, "interval")}
                                                onChange={(e) => this.setTmpData(e, "interval", index, 2)}
                                            />
                                        </TableCell>
                                        {/* Rítmo */}
                                        <TableCell align="right" sx={{maxWidth : 70}}>
                                        <TextField
                                                value={row.rythm === "" ? this.state.tmp_value[index][3] : row.rythm}
                                                id="rythm"
                                                onBlur={(e) => this.setData("rythm", index, 3)}
                                                onFocus={() => this.handleOnFocus(index, 3, "rythm")}
                                                onChange={(e) => this.setTmpData(e, "rythm", index, 3)}
                                            />
                                        </TableCell>
                                        {/* Métodos */}
                                        <TableCell align="right">
                                            <TextField
                                                value={row.method === "" ? this.state.tmp_value[index][4] : row.method}
                                                id="method"
                                                onBlur={(e) => this.setData("method", index, 4)}
                                                onFocus={() => this.handleOnFocus(index, 4, "method")}
                                                onChange={(e) => this.setTmpData(e, "method", index, 4)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                </Fragment>
                            ))}
                            </TableBody>
                        : null }
                        <TableBody>
                            <TableCell >
                                <IconButton aria-label="add row" onClick={() => this.addRow()}>
                                        <AddIcon />
                                </IconButton>
                            </TableCell>
                        </TableBody>
                    </Table>
                    </TableContainer>
                    <Modal
                        open={this.state.modal_open}
                        onClose={() => this.closeModal()}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={{position : "absolute", top: "30%", left: "30%", transform: "'translate(-50%, -50%)'", width: 700, backgroundColor : "black", border: "2px solid black", boxShadow: 20, p: 4}}>
                            <YouTube videoId={this.state.modal_url} title={this.state.modal_nome}/>
                        </Box>
                    </Modal>
            </Fragment>
        )
    }

}

export default TrainContent;