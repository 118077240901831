/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsconfig = {
    Auth: {
        identityPoolId: 'us-east-1:0f70dcfc-434b-434a-865b-40538b64e451', // REQUIRED - Amazon Cognito Identity Pool ID
        region: 'us-east-1', // REQUIRED - Amazon Cognito Region
        userPoolId: 'us-east-1_NDIThw9nx', // OPTIONAL - Amazon Cognito User Pool ID
        userPoolWebClientId: '45ah5185bueac5rl2ovcagvkgq'
    },
    API: {
        endpoints: [{
            name : "PersonalApp",
            endpoint: "https://api.fitnessconsultancy.fit",
            region: "us-east-1",
            paths: ['/']
        }]
    },
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:0f70dcfc-434b-434a-865b-40538b64e451",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_NDIThw9nx",
    "aws_user_pools_web_client_id": "45ah5185bueac5rl2ovcagvkgq",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
}

export default awsconfig;
