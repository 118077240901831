import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { IconButton } from '@mui/material';
import './StoryIcon.css';

export default function StoryIcon(props){

    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);

    const storyText = {
        color: '#ffffff',
        opacity: '40%',
        fontSize: 10,
        textTransform: 'uppercase'
    }

    const handleStory = (event) =>{
        console.log("clicou nessa desgraça", event.target.alt)
        setOpen(true);
    }

    const style = {
        position: "absolute",
        left: "50%",
        top: "50%",
        margin: 0,
        width: "80%",
        height: "80%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "rgba(0,0,0,1)",
        outline: "none"
      };

    const urls = {
        "biceps" : "UiJGatq7_6A",
        "triceps" : "gC31W7AF-cc",
        "peito" : "Kl87ctrBvcI",
        "costas" : "c5RcbgGgyBg",
        "coxas" : "3uyZGJzcshM",
    }

    const typeImage = '/images/icons/'+ props.name + '.png'


    return(
        <div>
            <div>
                <div className='story-border' onClick={handleStory}>
                    <img className='component-story-img' alt={props.name} src={process.env.PUBLIC_URL + typeImage}/>
                </div>
                <Typography sx={storyText} align='center'> {props.name} </Typography>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className='story-modal-close'>
                            <IconButton sx={{color : "rgba(249,88,54,1)"}} aria-label="close window" component="label" size="large" onClick={handleClose}>
                                <HighlightOffIcon />
                            </IconButton>
                        </div>
                        <iframe width="100%" height="100%" src={"https://www.youtube.com/embed/"+urls[props.name]}
                            frameborder="0" 
                            title="Leonardo Eliseo - Personal Trainer"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                            >
                        </iframe>
                    </Box>
                </Modal>
            </div>
        </div>
    )
}
