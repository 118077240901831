import React from 'react';
import { Fragment, Component } from 'react';
import './mobileassessment.css'
import { Typography } from '@mui/material';
import StoryIcon from '../../components/storyicon/StoryIcon';
import WorkoutListMobile from '../../components/workoutlist/WorkoutListMobile';
import WorkoutHeaderMobile from '../../components/workoutheader/WorkoutHeaderMobile';
import moment from 'moment';

export default class DesktopAssessment extends Component{
  constructor(props){
    super(props);
    this.state = {
      isLoading: true
    }
  }

  setDataForMobile(dateObject){
      const originalDateFormat = dateObject;
      const momentDate = moment(originalDateFormat, 'DD/MM/YYYY');
      const day = momentDate.format('DD');
      const month = momentDate.format('MMM').toUpperCase();
      const formattedDate = [parseInt(day), month];
      return formattedDate
  }

  componentDidMount(){
    const workout_start = this.setDataForMobile(this.props.data.workout_start)
    const workout_end = this.setDataForMobile(this.props.data.workout_end)

    this.setState({
      workout_start: workout_start,
      workout_end : workout_end,
      isLoading: false
    })
  }

  render(){

    return (
      <Fragment>
        {this.state.isLoading ? null :
        <Fragment>
              <div className='mobile-container'>
                <div className='mobile-header'>
                  <div className='mobile-header-logo'>
                    <img className='mobile-header-image' alt="Leonardo Eliseo Personal Trainer" src={process.env.PUBLIC_URL + '/images/HorizontalPrancheta 1.png'} />
                  </div>
                  <div className='mobile-header-info'>
                    <Typography variant='subtitle2' align='center' sx={{fontSize: 10, padding:'10%', paddingTop: 0, paddingBottom: 0, textTransform: 'uppercase', opacity: '40%'}}> 
                      Observações Gerais Sobre os Grupos Musculares </Typography>
                    <div className='mobile-header-stories'>
                      <div className='mobile-header-story'>
                        <StoryIcon name='triceps'/>
                      </div>
                      <div className='mobile-header-story'>
                        <StoryIcon name='biceps'/>
                      </div>
                      <div className='mobile-header-story'>
                        <StoryIcon name='peito'/>
                      </div>
                      <div className='mobile-header-story'>
                        <StoryIcon name='costas'/>
                      </div>
                      <div className='mobile-header-story'>
                        <StoryIcon name='coxas'/>
                      </div>
                    </div>
                  </div>
                  <div className='mobile-header-person'>
                    <WorkoutHeaderMobile title={this.props.data.workout_name} workout_start={this.state.workout_start} workout_end={this.state.workout_end} athlete_name={this.props.data.athlete_name}/>
                  </div>
                </div>
                <div className='mobile-content'>
                  <div className='mobile-content-card'>
                    <WorkoutListMobile train_data={this.props.data.train_data}/>
                  </div>
                </div>
              </div>
          </Fragment>
        }
        </Fragment>
    );
  }
}